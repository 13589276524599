/**
 * @module useAuthStore
 * @description This module describes all methods used to authenticate user
 * @see {@link https://users.beauty.musqogee.com/api/documentation}
 */

import { ref } from 'vue'
import { defineStore } from 'pinia'
import { useNotificationsStore } from '@/stores/notifications'
import ApiService from '@/services/api.service'
import JwtService from '@/services/jwt.service'
import QuizService from '@/services/quiz.service'
import i18n from '@/i18n.js'

const { t } = i18n.global

export const useAuthStore = defineStore('auth', () => {
  /**
   * @constant user [mutable]
   * @type {Object}
   * @description Object with user auth data
   */
  const user = ref({})

  /**
   * @constant isAuthenticated [mutable]
   * @type {Boolean}
   * @description Shows current user auth status
   */
  const isAuthenticated = ref(!!JwtService.getToken())

  /**
   * @constant forms [mutable]
   * @type {Object}
   * @description Used as v-model for Auth pages forms and as payload for REST Api
   */
  const forms = ref({
    /**
     * @param {String} email
     * @param {String} password
     * @param {String} captcha - Google reCaptcha token
     */
    signInClient: {
      email: '',
      password: '',
      captcha: '',
    },

    /**
     * @param {String} code - access token recieved from google Sign-in
     * @param {String} profileId - quiz id from localStorage
     */
    signInGoogle: {
      code: '',
      profileId: QuizService.getQuizId()
    },

    /**
     * @param {String} email
     * @param {String} profileId - quiz id from localStorage
     * @param {Boolean} agree - checkbox for 'Agree with Terms & Conditions'
     */
    signUp: {
      email: '',
      profileId: QuizService.getQuizId(),
      agree: false,
    },

    /**
     * @param {String} invitation_token - token from URL params
     * @param {String} name - first name
     * @param {String} lname - last name
     * @param {String} password - password
     * @param {String} password_confirmation - repeated password
     */
    signUpComplete: {
      invitation_token: '',
      name: '',
      lname: '',
      password: '',
      password_confirmation: '',
    },

    /**
     * @param {String} email
     */
    forgotPassword: {
      email: '',
    },

    /**
     * @param {String} token - token from URL params
     * @param {String} password - password
     * @param {String} password_confirmation - repeated password
     */
    resetPassword: {
      token: '',
      password: '',
      password_confirmation: '',
    },
  })

  /**
   * @constant validations [mutable]
   * @type {Object}
   * @description
   * This object is used to show validation inside forms on Auth pages
   * @param {String|Boolean} status - validation state of input. 'error' | false
   * @param {Array} feedback - array of messages below input
   *
   * @see {@link https://www.naiveui.com/en-US/os-theme/components/form}
   * @see {@link https://www.naiveui.com/en-US/os-theme/components/input}
   */
  const validations = ref({
    signIn: {
      email: { status: false, feedback: [] },
      password: { status: false, feedback: [] },
      captcha: { status: false, feedback: [] },
    },
    signUp: {
      email: { status: false, feedback: [] },
      agree: { status: false, feedback: [] },
    },
    signUpComplete: {
      name: { status: false, feedback: [] },
      lname: { status: false, feedback: [] },
      password: { status: false, feedback: [] },
      password_confirmation: { status: false, feedback: [] },
    },
    forgotPassword: {
      email: { status: false, feedback: [] },
    },
    resetPassword: {
      password: { status: false, feedback: [] },
      password_confirmation: { status: false, feedback: [] },
    },
  })

  /**
   * @constant responses [mutable]
   * @type {Object}
   * @description
   * This object contains current API response state for specific reqests
   * You can use that to show preloaders or placeholders in templates
   * @example
   * <div v-if="store.responses.getChatHistory">
   *   I am placeholder
   * </div>
   * <div v-else>
   *   Loaded content
   * </div>
   */
  const responses = ref({
    signIn: false,
    signUp: false,
    signUpComplete: false,
    forgotPassword: false,
    resetPassword: false,
    logOut: false,
  })

  /**
   * @function parseToken
   * @description Helps to decode token string
   * @param {String} token - access token received from server
   * @returns {Object}
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/parse}
   * @see {@link https://developer.mozilla.org/ru/docs/Web/API/atob}
   */
  function parseToken(token) {
    return JSON.parse(atob(token.split(".")[1]))
  }

  /**
   * @function clearForm
   * @description Clears form v-model
   * @param {String} form - name of form to handle
   */
  function clearForm({ form }) {
    const target = forms.value[form]
    Object.keys(target).forEach(key => {
      if (typeof target[key] == 'string') target[key] = ''
      else if (typeof target[key] == 'boolean') target[key] = false
    })
  }

  /**
   * @function saveFormError
   * @description
   * This function pushes errors received from server in reactive variable "forms"
   * @param {String} form - name of form to handle
   * @param {String} field - field inside that form
   * @param {Array} messages - messages received from server
   */
  function saveFormError({ form, field, messages }) {
    const target = validations.value[form][field]
    if (target) {
      target.status = 'error'
      target.feedback = messages
    }
  }

  /**
   * @function clearFormErrors
   * @description
   * Function clear errors in specific form
   * If you pass only first param it will clear all error messges inside that form
   * If you pass second param it will clear error only in that specific field
   * @param {String} form - name of form to clear errors from, required
   * @param {String} field - name of field to clear errors from
   */
  function clearFormErrors({ form, field }) {
    const target = validations.value[form]

    function clearField(field) {
      target[field].status = false
      target[field].feedback = []
    }

    if (field) {
      clearField(field)
    } else {
      Object.keys(target).forEach(field => { clearField(field) })
    }
  }

  /**
   * @function setAuth
   * @description Saves user auth status in Pinia and localStorage
   * @param {String} token - access token received from server
   */
  function setAuth(token) {
    let parsedToken = parseToken(token)

    isAuthenticated.value = true
    user.value = { token: token, ...parsedToken }
    JwtService.saveToken(token)
  }

  /**
   * @function purgeAuth
   * @description Do the opposite of function above. Clears all auth data.
   */
  function purgeAuth() {
    isAuthenticated.value = false
    user.value = {}
    JwtService.destroyToken()
  }

  /**
   * @function signIn
   * @description Function to sign in user in cabinet
   *
   * @param {String} method - 'client' | 'google'
   * Based on this param function will change method url and payload
   *
   * @returns {Promise} Promise object represents server answer
   *
   * @throws
   * Will fire notification if server returned { success: false }
   * Will show error messages in SignIn form
   * Will throw Promise reject if Axios catches some error in .then()
   */
  function signIn({ method }) {
    const notificationsStore = useNotificationsStore()
    return new Promise((resolve, reject) => {
      clearFormErrors({ form: 'signIn' })
      responses.value.signIn = true

      let url
      let payload

      if (method == 'client') {
        url = import.meta.env.VITE_AUTH_SIGNIN
        payload = forms.value.signInClient
      } else if (method == 'google') {
        url = import.meta.env.VITE_AUTH_SIGNIN_GOOGLE
        payload = forms.value.signInGoogle
      }

      ApiService.init(import.meta.env.VITE_USER_API)
      ApiService.post(url, payload)
        .then(({data}) => {
          if (data.success) {
            setAuth(data.access_token)
            notificationsStore.pushNotification({
              message: `${t('signin.welcome')}, ${user.value.lname}!`,
              type: 'success'
            })
            resolve({data})
          } else {
            notificationsStore.pushNotification({
              message: data.message,
              type: 'error'
            })
            reject({data})
          }
        })
        .catch(err => {
          const error = err.response.data
          notificationsStore.pushNotification({
            message: error.message,
            type: 'error'
          })
          if (error.errors) {
            Object.keys(error.errors).forEach(key => {
              saveFormError({
                form: 'signIn',
                field: key,
                messages: error.errors[key]
              })
            })
          }
          console.error(error)
          reject(err)
        })
        .finally(() => {
          JwtService.removeTokenPending()
          setTimeout(() => { responses.value.signIn = false }, 500)
        })
    })
  }

  /**
   * @function signUp
   * @description Function to register new user
   *
   * @returns {Promise} Promise object represents server answer
   *
   * @throws
   * Will fire notification if server returned { success: false }
   * Will show error messages in Sign Up form
   * Will throw Promise reject if Axios catches some error in .then()
   */
  function signUp() {
    const notificationsStore = useNotificationsStore()
    return new Promise((resolve, reject) => {
      clearFormErrors({ form: 'signUp' })
      responses.value.signUp = true

      ApiService.init(import.meta.env.VITE_USER_API)
      ApiService.post(import.meta.env.VITE_AUTH_SIGNUP, forms.value.signUp)
        .then(({data}) => {
          if (data.success) {
            notificationsStore.pushNotification({
              message: data.message,
              type: 'success'
            })
            resolve({data})
          } else {
            notificationsStore.pushNotification({
              message: data.message,
              type: 'error'
            })
            reject({data})
          }
        })
        .catch(err => {
          const error = err.response.data
          notificationsStore.pushNotification({
            message: error.message,
            type: 'error'
          })
          if (error.errors) {
            Object.keys(error.errors).forEach(key => {
              saveFormError({
                form: 'signUp',
                field: key,
                messages: error.errors[key]
              })
            })
          }
          console.error(error)
          reject(err)
        })
        .finally(() => {
          setTimeout(() => { responses.value.signUp = false }, 500)
        })
    })
  }

  /**
   * @function signUpComplete
   * @description Function to complete registration process
   *
   * @returns {Promise} Promise object represents server answer
   *
   * @throws
   * Will fire notification if server returned { success: false }
   * Will show error messages in SignUpComplete form
   * Will throw Promise reject if Axios catches some error in .then()
   */
  function signUpComplete() {
    const notificationsStore = useNotificationsStore()
    return new Promise((resolve, reject) => {
      clearFormErrors({ form: 'signUpComplete' })
      responses.value.signUpComplete = true

      ApiService.init(import.meta.env.VITE_USER_API)
      ApiService.post(import.meta.env.VITE_AUTH_SIGNUP_COMPLETE, forms.value.signUpComplete)
        .then(({data}) => {
          if (data.success) {
            setAuth(data.access_token)
            resolve({data})
          } else {
            notificationsStore.pushNotification({
              message: data.message,
              type: 'error'
            })
            reject({data})
          }
        })
        .catch(err => {
          const error = err.response.data
          notificationsStore.pushNotification({
            message: error.message,
            type: 'error'
          })
          if (error.errors) {
            Object.keys(error.errors).forEach(key => {
              saveFormError({
                form: 'signUpComplete',
                field: key,
                messages: error.errors[key]
              })
            })
          }
          console.error(error)
          reject(err)
        })
        .finally(() => {
          setTimeout(() => { responses.value.signUpComplete = false }, 500)
        })
    })
  }

  /**
   * @function forgotPassword
   * @description Function to request email link with reset password inviation
   *
   * @returns {Promise} Promise object represents server answer
   *
   * @throws
   * Will fire notification if server returned { success: false }
   * Will show error messages in Sign Up form
   * Will throw Promise reject if Axios catches some error in .then()
   */
  function forgotPassword() {
    const notificationsStore = useNotificationsStore()
    return new Promise((resolve, reject) => {
      clearFormErrors({ form: 'forgotPassword' })
      responses.value.forgotPassword = true

      ApiService.init(import.meta.env.VITE_USER_API)
      ApiService.post(import.meta.env.VITE_AUTH_FORGOT_PASSWORD, forms.value.forgotPassword)
        .then(({data}) => {
          if (data.success) {
            notificationsStore.pushNotification({
              message: data.message,
              type: 'success'
            })
            resolve({data})
          } else {
            notificationsStore.pushNotification({
              message: data.message,
              type: 'error'
            })
            reject({data})
          }
        })
        .catch(err => {
          const error = err.response.data
          notificationsStore.pushNotification({
            message: error.message,
            type: 'error'
          })
          if (error.errors) {
            Object.keys(error.errors).forEach(key => {
              saveFormError({
                form: 'forgotPassword',
                field: key,
                messages: error.errors[key]
              })
            })
          }
          console.error(error)
          reject(err)
        })
        .finally(() => {
          setTimeout(() => { responses.value.forgotPassword = false }, 500)
        })
    })
  }

  /**
   * @function resetPassword
   * @description Function to reset forgotten user password
   *
   * @returns {Promise} Promise object represents server answer
   *
   * @throws
   * Will fire notification if server returned { success: false }
   * Will show error messages in Sign Up form
   * Will throw Promise reject if Axios catches some error in .then()
   */
  function resetPassword() {
    const notificationsStore = useNotificationsStore()
    return new Promise((resolve, reject) => {
      clearFormErrors({ form: 'resetPassword' })
      responses.value.resetPassword = true

      ApiService.init(import.meta.env.VITE_USER_API)
      ApiService.post(import.meta.env.VITE_AUTH_RESET_PASSWORD, forms.value.resetPassword)
        .then(({data}) => {
          if (data.success) {
            notificationsStore.pushNotification({
              message: data.message,
              type: 'success'
            })
            resolve({data})
          } else {
            notificationsStore.pushNotification({
              message: data.message,
              type: 'error'
            })
            reject({data})
          }
        })
        .catch(err => {
          const error = err.response.data
          notificationsStore.pushNotification({
            message: error.message,
            type: 'error'
          })
          if (error.errors) {
            Object.keys(error.errors).forEach(key => {
              saveFormError({
                form: 'resetPassword',
                field: key,
                messages: error.errors[key]
              })
            })
          }
          console.error(error)
          reject(err)
        })
        .finally(() => {
          setTimeout(() => { responses.value.resetPassword = false }, 500)
        })
    })
  }

  /**
   * @function logOut
   * @description
   * Function to logout user
   *
   * @returns {Promise} Promise object represents server answer
   *
   * @throws
   * Will fire notification if server returned { success: false }
   * Will throw Promise reject if Axios catches some error in .then()
   */
  function logOut() {
    const notificationsStore = useNotificationsStore()
    return new Promise((resolve, reject) => {
      ApiService.init(import.meta.env.VITE_USER_API)
      ApiService.setHeader()
      responses.value.logOut = true
      ApiService.post(import.meta.env.VITE_AUTH_LOGOUT)
        .then(({data}) => {
          if (data.success) {
            purgeAuth()
            resolve({data})
          } else {
            notificationsStore.pushNotification({
              message: data.message,
              type: 'error'
            })
            reject({data})
          }
        })
        .catch(err => {
          const error = err.response.data
          notificationsStore.pushNotification({
            message: error.message,
            type: 'error'
          })
          console.error(error)
          reject(err)
        })
        .finally(() => {
          setTimeout(() => { responses.value.logOut = false }, 500)
        })
    })
  }

  /**
   * @function refreshToken
   * @description Function to refresh current access token and re-save it
   *
   * @returns {Promise} Promise object represents server answer
   *
   * @throws
   * Will purge current access token if server returns error 400
   * Will throw Promise reject if Axios catches some error in .then()
   */
  function refreshToken() {
    return new Promise((resolve, reject) => {
      ApiService.init(import.meta.env.VITE_USER_API)
      ApiService.setHeader()
      ApiService.post(import.meta.env.VITE_AUTH_REFRESH)
        .then(({data}) => {
          if (data.success) {
            resolve(data.access_token)
          }
        })
        .catch(err => {
          console.error(err)
          reject(err)
        })
    })
  }

  /**
   * @function verifyAuth
   * @description
   * Function to verify current access token expire
   * If token isn't expired - save it and continue
   * If token is expired - request new one save it
   *
   * @returns {Promise} Promise object represents server answer
   *
   * @throws
   * Will purge current access token if server returns error
   * Will throw Promise reject if Axios catches some error in .then()
   */
  function verifyAuth() {
    return new Promise((resolve, reject) => {
      ApiService.init(import.meta.env.VITE_USER_API)
      let token = JwtService.getToken()
      if (token) {
        let parsedToken = parseToken(token)
        if (parsedToken.exp > Date.now() / 1000) {
          setAuth(token)
          resolve(token)
        } else {
          if (JwtService.tokenIsPending()) {
            return setTimeout(() => {
              let token = JwtService.getToken()
              setAuth(token)
              resolve(token)
            }, 500)
          }
          JwtService.setTokenPending()
          refreshToken()
            .then(result => {
              setAuth(result)
              resolve(result)
            })
            .catch(error => {
              purgeAuth()
              reject(error)
            })
            .finally(()=>{
              JwtService.removeTokenPending()
            })
        }
      } else {
        purgeAuth()
        reject({ data: { message: "Token not found!" } })
      }
    })
  }


  return {
    user,
    isAuthenticated,
    forms,
    validations,
    responses,

    setAuth,
    purgeAuth,

    clearForm,
    clearFormErrors,
    saveFormError,

    signIn,
    signUp,
    signUpComplete,
    forgotPassword,
    resetPassword,
    logOut,

    refreshToken,
    verifyAuth,
  }
})
