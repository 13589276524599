import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import i18n from '@/i18n.js'

const { t } = i18n.global

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    { path: '/', name: t('routing.home'), redirect: '/cabinet' },

    {
      path: '/auth',
      redirect: '/auth/signin',
      name: 'Auth',
      children: [
        {
          path: '/auth/signin',
          name: 'SignIn',
          component: () => import('@/views/auth/AuthSignIn.vue'),
          meta: {
            layout: 'auth',
            auth: false,
            title: t('routing.signin'),
          },
        },
        {
          path: '/auth/signup',
          name: 'SignUp',
          component: () => import('@/views/auth/AuthSignUp.vue'),
          meta: {
            layout: 'auth',
            auth: false,
            title: t('routing.signup'),
          },
        },
        {
          path: '/auth/signup-complete',
          name: 'SignUpComplete',
          component: () => import('@/views/auth/AuthSignUpComplete.vue'),
          meta: {
            layout: 'auth',
            auth: false,
            title: t('routing.signupComplete'),
          },
        },
        {
          path: '/auth/forgot-password',
          name: 'ForgotPassword',
          component: () => import('@/views/auth/AuthForgotPassword.vue'),
          meta: {
            layout: 'auth',
            auth: false,
            title: t('routing.forgotPassword'),
          },
        },
        {
          path: '/auth/reset-password',
          name: 'ResetPassword',
          component: () => import('@/views/auth/AuthResetPassword.vue'),
          meta: {
            layout: 'auth',
            auth: false,
            title: t('routing.resetPassword'),
          },
        },
      ],
    },

    {
      path: '/cabinet',
      redirect: 'cabinet/chat',
      name: 'Cabinet',
      children: [
        {
          path: '/cabinet/chat',
          name: 'Chat',
          component: () => import('@/views/chat/Chat.vue'),
          meta: {
            layout: 'chat',
            auth: true,
            title: t('routing.chat'),
          },
        },
        {
          path: '/cabinet/dashboard',
          name: 'Dashboard',
          component: () => import('@/views/dashboard/Dashboard.vue'),
          meta: {
            layout: 'dashboard',
            auth: true,
            title: t('routing.dashboard'),
          },
        },
        {
          path: '/cabinet/profile',
          name: 'Profile',
          component: () => import('@/views/profile/Profile.vue'),
          meta: {
            layout: 'dashboard',
            auth: true,
            title: t('routing.profile'),
          },
        },
      ]
    },

    {
      path: '/:pathMatch(.*)*',
      name: 'ErrorNotFound',
      component: () => import('@/views/error/ErrorNotFound.vue'),
    },
  ],
})

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore()

  if (to.meta.auth) {
    Promise.all([authStore.verifyAuth()])
      .then(() => { next() })
      .catch(() => { next({ name: 'SignIn' }) })
  } else {
    next()
  }

  document.title =
    to.meta.title
      ? `${t('routing.base')} - ${to.meta.title}`
      : t('routing.base')

  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 100)
})

export default router
