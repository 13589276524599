const ru = {
  routing: {
    base: "Lycaste кабинет",
    home: "Домашняя страница",
    signin: "Войти",
    signup: "Зарегистрироваться",
    signupComplete: "Завершить регистрацию",
    forgotPassword: "Восстановление пароля",
    resetPassword: "Создание нового пароля",
    chat: "Консультант Lycaste AI",
    dashboard: "Бьюти профиль",
    profile: "Личный профиль",
    shop: "Магазин",
    calendar: "Календарь",
    about: "О нас",
  },
  google: {
    buttonText: "Продолжить с Google",
    disclaimer: "Этот сайт защищен reCAPTCHA и к нему применяются <a href='https://policies.google.com/privacy'>Политика конфиденциальности</a> и <a href='https://policies.google.com/terms'>Условия использования</a> Google."
  },
  signup: {
    title: "Создать  аккаунт",
    description: "Результаты работы системы Lycaste AI доступны в личном кабинете. Чтобы получить доступ, вам необходимо либо зарегистрироваться, либо войти под существующей учетной записью.",
    divider: "ИЛИ",
    inputPlaceholder: "Email",
    agreement: "Соглашаюсь на обработку ",
    terms: "персональных данных",
    termsValidation: "Пожалуйста, прочитайте и согласитесь с нашей политикой в отношении обработки персональных данных.",
    submitButton: "Зарегистрироваться",
    signInText: "У вас уже есть аккаунт?",
    signinButton: "Войти"
  },
  signin: {
    title: "Добро пожаловать в личный кабинет Lycaste",
    description: "Стремитесь к максимально здоровому внешнему виду Вашей кожи!",
    divider: "ИЛИ",
    emailPlaceholder: "Email",
    passwordPlaceholder: "Пароль",
    submitButton: "Войти",
    signupButton: "Создать аккаунт",
    forgotButton: "Забыли пароль?",
    welcome: "Добро пожаловать",
  },
  forgotPassword: {
    title: "Восстановление пароля",
    description: "Забыли пароль? Не беспокойтесь! Просто введите свой адрес электронной почты, и мы вышлем ссылку для создания нового.",
    inputPlaceholder: "Email",
    submitButton: "Сброс пароля",
    cancelButton: "Отмена",
  },
  signupComplete: {
    "title": "Завершение регистрации",
    "description": "Вы почти закончили! Пожалуйста, предоставьте некоторую информацию для завершения регистрации.",
    "namePlaceholder": "Имя",
    "lnamePlaceholder": "Фамилия",
    "passwordPlaceholder": "Пароль",
    "passwordConfirmPlaceholder": "Подтвердите пароль",
    "submitButton": "Отправить"
  },
  resetPassword: {
    "title": "Создание нового пароля",
    "description": "Пожалуйста, создайте новый надежный пароль, который вы не используете на других сайтах.",
    "passwordPlaceholder": "Пароль",
    "passwordConfirmPlaceholder": "Подтвердите пароль",
    "submitButton": "Отправить"
  },
  userMenu: {
    profile: "Профиль",
    settings: "Настройки",
    logout: "Выйти",
  },
  floatingButtons: {
    analize: "Анализировать кожу",
    continue: "Продолжить тест",
    chat: "Lycaste AI",
  },
  dashboard: {
    recomendations: {
      title: "Советы и рекомендации по уходу",
      empty: "Рекомендации не готовы",
      button: "Пройдите тестирование",
    },
    skinState: {
      title: "Базовое состояние кожи",
      empty: "Н/Д"
    },
    skinConcerns: {
      title: "Проблемы с кожей",
      empty: "Нет данных",
    },
    ingredients: {
      title: "Рекомендуемые ингредиенты",
      empty: "Ингредиенты не найдены",
      button: "Пройдите тестирование",
      viewAll: "Посмотреть все игредиенты",
      showLess: "Показать меньше игредиентов",
    },
    products: {
      title: "Рекомендуемые продукты",
      empty: "Рекомендованных товаров пока нет",
      button: "Пройдите тестирование",
    },
  },
  chat: {
    backButton: "В бьюти профиль",
    unauthorized: "Упс! Похоже, Вы не авторизованы!",
    empty: {
      title: "Сообщений нет.",
      text: "Похоже, Вы ещё не общались.<br>Давайте же начнём!"
    },
    voice: {
      unsupported: "Извините, но эта функция не поддерживается Вашим браузером",
      micUsage: "Предоставьте доступ к микрофону, чтобы в полной мере использовать Lycaste AI",
      repeat: "Извините, можете повторить, пожалуйста?",
    },
    input: {
      placeholder: "Написать сообщение...",
    },
    message: {
      recognized: "Распознано по речи с помощью Lycaste AI",
    },
  },
  profile: {
    address: {
      title: "Адрес",
      country: "Страна",
      state: "Штат",
      city: "Город",
      street: "Улица",
      zipcode: "Zip код",
    },
    personal: {
      title: "Персональные данные",
      email: "Email",
      phone: "Телефон",
    },
  },
  footer: {
    brand: "Lycaste.ru",
    terms: "Условия использования",
    policy: "Политика конфиденциальности",
  }
}

export default ru
