import axios from 'axios'
import JwtService from '@/services/jwt.service.js'
import { useAuthStore } from '../stores/auth'
import router from '../router'

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init(baseUrl) {
    axios.defaults.baseURL = baseUrl

    axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          const authStore = useAuthStore()

          authStore.purgeAuth()
          router.push({ name: 'SignIn' })
        }
        return Promise.reject(error)
      }
    )
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    axios.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${JwtService.getToken()}`
  },

  query(resource, params) {
    return axios.get(resource, params).catch(error => {
      throw new Error(`[KT] ApiService ${error}`)
    })
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, queries) {
    const params = new URLSearchParams(queries)
    return queries ? axios.get(`${resource}/?${params}`) : axios.get(resource)
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return axios.post(`${resource}`, params)
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return axios.put(`${resource}/${slug}`, params)
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return axios.put(`${resource}`, params)
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return axios.delete(resource)
  },
}

export default ApiService
