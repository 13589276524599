const en = {
  routing: {
    base: "Lycaste Cabinet",
    home: "Home",
    signin: "Sign In",
    signup: "Sign Up",
    signupComplete: "Complete Registation",
    forgotPassword: "Password Recovery",
    resetPassword: "Create New Password",
    chat: "Open AI Chat",
    dashboard: "Beauty Profile",
    profile: "Personal Profile",
    shop: "Shop",
    calendar: "Calendar",
    about: "About",
  },
  google: {
    buttonText: "Continue with Google",
    disclaimer: "This site is protected by reCAPTCHA and the Google <a href='https://policies.google.com/privacy'>Privacy Policy</a> and <a href='https://policies.google.com/terms'>Terms of Service</a> apply."
  },
  signup: {
    title: "Create an Account",
    description: "The results of the Lycaste AI system are available in your personal account. To gain access, you need to either register or log in with an existing account.",
    divider: "OR",
    inputPlaceholder: "Email",
    agreement: "I agree to the ",
    terms: "Privacy Policy",
    termsValidation: "Please read and agree with our Privacy Policy.",
    submitButton: "Sign Up",
    signInText: "Already have an account?",
    signinButton: "Sign In"
  },
  signin: {
    title: "Welcome to Lycaste Personal Cabinet",
    description: "Achieve the maximum health and appearance you want.",
    divider: "OR",
    emailPlaceholder: "Email",
    passwordPlaceholder: "Password",
    submitButton: "Sign In",
    signupButton: "Create an Account",
    forgotButton: "Forgot Password?",
    welcome: "Welcome back",
  },
  forgotPassword: {
    title: "Password Recovery",
    description: "Forgot your password? No worries! Just enter your email address and we'll send a link to create new one.",
    inputPlaceholder: "Email",
    submitButton: "Reset Password",
    cancelButton: "Cancel",
  },
  signupComplete: {
    title: "Complete Registration",
    description: "You are nearly there! Please provide some information to complete registration.",
    namePlaceholder: "First Name",
    lnamePlaceholder: "Last Name",
    passwordPlaceholder: "Password",
    passwordConfirmPlaceholder: "Confirm Password",
    submitButton: "Submit",
  },
  resetPassword: {
    title: "Create New Password",
    description: "Please create a new secure password that you don't use on any other website.",
    passwordPlaceholder: "Password",
    passwordConfirmPlaceholder: "Confirm Password",
    submitButton: "Submit",
  },
  userMenu: {
    profile: "Profile",
    settings: "Settings",
    logout: "Logout",
  },
  floatingButtons: {
    analize: "Analyze Skin",
    continue: "Continue Scan",
    chat: "Lycaste AI",
  },
  dashboard: {
    recomendations: {
      title: "Skin Tips & Advice",
      empty: "Recomendation is not ready",
      button: "Analyze Skin",
    },
    skinState: {
      title: "Essential Skin State",
      empty: "No Data",
    },
    skinConcerns: {
      title: "Skin Concerns",
      empty: "No Data",
    },
    ingredients: {
      title: "Recommended Ingredients",
      empty: "Ingredients not found",
      button: "Analyze Skin",
      viewAll: "View All Assessments",
      showLess: "Show Less Assessments",
    },
    products: {
      title: "Recommended Products",
      empty: "No recommended products yet",
      button: "Analyze Skin",
    },
  },
  chat: {
    backButton: "Back to Beauty Profile",
    unauthorized: "Oops! Looks like you are unauthorized.",
    empty: {
      title: "No messages found.",
      text: "Looks like you haven't talked yet.<br>Let's start now!"
    },
    voice: {
      unsupported: "Sorry, but this feature is not supported on your browser",
      micUsage: "Please grant access to your microphone to fully use Lycaste AI",
      repeat: "Sorry, can you repeat yourself please?",
    },
    input: {
      placeholder: "Write message...",
    },
    message: {
      recognized: "Recognized from speech using Lycaste AI",
    },
  },
  profile: {
    address: {
      title: "Address",
      country: "Country",
      state: "State",
      city: "City",
      street: "Street",
      zipcode: "Zip Code",
    },
    personal: {
      title: "Personal Data",
      email: "Email",
      phone: "Phone",
    },
  },
  footer: {
    brand: "Lycaste.com",
    terms: "Terms & Conditions",
    policy: "Privacy Policy",
  }
}

export default en
