const ID_TOKEN_KEY = 'quiz_uid'

export const getQuizId = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY) || ''
}

export const saveQuizId = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token)
}

export const destroyQuizId = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY)
}

export const redirectQuiz = id => {
  let url = id ? `${import.meta.env.VITE_QUIZ_URL}?uid=${id}` : import.meta.env.VITE_QUIZ_URL
  window.location.assign(url)
}

export default {
  getQuizId,
  saveQuizId,
  destroyQuizId,
  redirectQuiz
}
