const ID_TOKEN_KEY = 'id_token'
const ID_TOKEN_PENDING = 'id_token_pending'

/** Access token stuff */
export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY)
}

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token)
}

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY)
}

/** Temporary localStorage recording */
export const tokenIsPending = () => {
  return window.localStorage.getItem(ID_TOKEN_PENDING)
}

export const setTokenPending = () => {
  window.localStorage.setItem(ID_TOKEN_PENDING, true)
}

export const removeTokenPending = () => {
  window.localStorage.removeItem(ID_TOKEN_PENDING)
}

export default {
  getToken,
  saveToken,
  destroyToken,

  tokenIsPending,
  setTokenPending,
  removeTokenPending,
}
