import { createI18n } from 'vue-i18n'

import en from './locales/en.js'
import ru from './locales/ru.js'

const messages = { en, ru }

const i18n = createI18n({
  legacy: false,
  locale: import.meta.env.VITE_APP_LANG,
  fallbackLocale: 'en',
  messages
})

export default i18n
